import viberlink_logo from "../assets/images/viberlink-black.png";
import { useState } from "react";
import { useCheckBillingHooks } from "../utils/CheckRegistrationHooks";
import { Link } from "react-router-dom";
import { convertToRupiah } from "../tools/numerictools";
import moment from "moment";

function CheckBillingPage() {
  const [registration_number, setRegistrationNumber] = useState();
  const { data, getData, error, loading, success } = useCheckBillingHooks();

  const onSubmitCheck = (event) => {
    event.preventDefault();
    getData({ n: registration_number.trim() });
  };

  return (
    <div className="page-content check-registration">
      <img src={viberlink_logo} className="mb-3" alt="" srcset="" width="300" />
      <div className="form-v4-content" style={{ display: "block" }}>
        <form
          className="form-detail"
          onSubmit={(event) => onSubmitCheck(event)}
        >
          <h2 className="text-center">Cek Tagihan Pelanggan</h2>
          <div className="row">
            <div className="col col-md-8">
              <label htmlFor="registration_number">
                No Registrasi/No. Pelanggan/No. Serial Modem/No. Handphone
              </label>
              <input
                type="text"
                name="registration_number"
                id="registration_number"
                className="input-text"
                value={registration_number}
                placeholder="REGVBxxxxx"
                onChange={(event) => setRegistrationNumber(event.target.value)}
              />
            </div>
            <div className="col-auto">
              <div className="form-row-last pt-4">
                <button
                  type="submit"
                  name="register"
                  style={{ height: "47px" }}
                  className="btn register btn-primary btn-block"
                  disabled={loading}
                >
                  {loading ? "Mengecek" : "Cek Billing"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="detail-registration mb-4">
          {success ? (
            <>
              {data.plan.internet_package ? (
                <div className="receipt-container mb-2">
                  <div className="text-container">
                    <h6>Paket yang berlaku saat ini:</h6>
                    <span className="receipt-number mb-2">
                      {data.plan.internet_package}
                    </span>
                    <span className="amount">
                      Jatuh Tempo:{" "}
                      {moment.unix(data.plan.end_period).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              ) : null}
              {data.billing.billing_number ? (
                <div className="receipt-container">
                  <div className="text-container">
                    <span className="receipt-number mb-2">
                      {data.billing.billing_number}
                    </span>
                    <span className="customer-name">{data.billing.name}</span>
                    <span className="package-name">
                      {data.billing.internet_package}
                    </span>
                    <span className="amount">
                      Rp {convertToRupiah(data.billing.final_amount || 0)}
                    </span>
                  </div>
                  <Link
                    to={`/billing/${data.billing.billing_number}/auth/${data.billing.payment_token}`}
                    className="btn btn-primary"
                  >
                    Bayar Sekarang
                  </Link>
                </div>
              ) : (
                <div className="alert alert-warning">Tidak ada tagihan</div>
              )}
            </>
          ) : null}
          {error ? (
            <div className="alert alert-danger">
              <span>{error}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CheckBillingPage;
